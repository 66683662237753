
.signpadModal{
    background-color: rgb(231, 230, 230);
    max-height: 40vh;
    min-height: 20vh;
    max-width: 40vw;
    min-width: 30vw;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.signpadModalHeading{
    width: 100%;
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
}

.signpadModalButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.signpadModalUpload, .signpadModalStencil{
    border-radius: 10px;
    background-color: rgba(141, 243, 183, 1);
    padding: 3%;
    text-align: center;
    width: 10rem;
    cursor: pointer;
    color: white;
}
.signpadModalUpload:hover{
    opacity: .5;
}
.signpadModalStencil:hover{
    opacity: .5;
}
.signpadModalStencil{
    background-color: #12b7e0;
}

.signpadStyle{
    border : 1px solid black;
    border-radius: 30px;
    width: 70%;
    background-color:#97cee4;
}