.app-layout {
  height: 100vh;
  width: 100vw;
}

.navbar-layout {
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.44);
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.44);
  -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.44);
}

.navbar-layout img {
  height: 50px;
}

.navbar-action-layout {
  display: flex;
}

.navbar-action-layout button {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  border: 0;
  margin-left: 10px;
  cursor: pointer;
}

.accept-btn {

  height: 35px;
  width: 150px;
  border-radius: 20px;
  border: 0;
  margin: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: rgba(141, 243, 183, 1);
}

.reject-btn {

  height: 35px;
  width: 150px;
  border-radius: 20px;
  border: 0;
  margin: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: rgba(243, 141, 141, 0.801);
}

.accept-btn:hover {
  background-color: rgba(89, 187, 130, 0.801);
}

.reject-btn:hover {
  background-color: rgba(204, 78, 78, 0.801);
}


.quote-editor-layout {
  padding: 0px 18vw;
  margin: 20px 0px;
  position: relative;
}

.alert-layout {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-main {
  background-color: rgb(189, 189, 189);
  padding: 20vh;
  border-radius: 10px;
}


.quote-info-layout {
  background-color: rgb(218, 216, 216);
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-info-box {
  width: 60vw;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
}

.quote-box-grid-files {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 17vh);
  margin-top: 30px;
}

.quote-box-item {
  height: 40px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.quote-box-item-sp {
  height: 40px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.invoice-amt-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0px;
}

.quote-sender-layout {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgb(228, 227, 227);
}

.quote-info-main-layout {
  background-color: rgb(228, 227, 227);
  padding: 20px;
}

.quote-sender-layout img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.quote-sender-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.quote-info-action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.quote-info-action-btns button {
  height: 35px;
  width: 150px;
  border-radius: 20px;
  border: 0;
  margin: 20px;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.quote-selector-btn {
  height: 40px;
  width: 200px; 
  background-color: #dbdbdb; 
  border-radius: 10px;
  margin : 1% 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-all;
  position: relative;
}

.quote-fill-dataBtn{
  position: fixed;
  z-index: 5;
}

.checkMarkCircle{
  position: absolute;
  top: -10px;
  background-color: white;
  border-radius: 50%;
  height: 50%;
  right: 0;
}

.quote-update-active{
  background-color: rgba(141, 243, 183, 1);
}
.quote-update-inactive{
  pointer-events: none;
}

.quote-selector-btn-active {
  background-color: #12b7e0;
}

.quoteModalBg, .folderModalBg{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 80px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.quoteModalCloseBx{
  display: block;
}

.quoteModalContent, .folderModalContent{
  background-color: #fefefe;
    margin: auto;
    padding: 20px 20px 10px 20px;
    border-radius: 30px;
    border:none;
    width: 40vw;
    /* min-height:10vh; */
    height: 80vh;
}

.folderModalContent{
  width: 70%;
}

.folderModalContent input[type='text']{
  border: none;
  border-bottom: 1px solid black;
}

.smlInputs{
  width: 40px;
  text-align: center;
  margin: 0 10px;
}

.quoteModalTitle{
  font-size: 18px;
  font-weight: 600;
  color: #12b7e0;
}

.quoteModalContentBody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 5%;
}

.quoteModalBody, .quoteModalSubBody, .quoteModalSubBodyContent{
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  justify-content: space-between;
}

.quoteModalSubBodyTitle{
  font-weight: 600;
  padding: 10px 0;
}

.quoteModalSubBodyContent > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 0;
}

.quoteModalLn1{
  flex:3;
}

.quoteModalLn2{
  flex:1;
}

.quoteModalLn3{
  flex: 3;
}

.quoteModalLn3 > input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  height: 18px;
}


input :focus{
  outline: none;
}

.searchAddressSite{
  width: 100%;
}
.client-input {
  border: 0px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.562);
  width: 100%;
  font-size: 13px;
}

.client-input:focus {
  outline: none;
}

@media only screen and (max-width: 900px) {
  .quote-selector-btn{
    width: 100%;
  }
  .quoteModalContent, .folderModalContent{
    width: 80vw;
  }
}